<template>
  <section class="order-details" v-if="trackOrderData">
    <OrderDetails class="container" :orderDetailsData="trackOrderData" />
  </section>
  <loader v-else />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import settings from '@/settings'
import PageMixin from '@/mixins/PageMixin'
import OrderDetails from '@/components/OrderDetails'

export default {
  components: {
    OrderDetails
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('orders', ['trackOrderData'])
  },
  methods: {
    ...mapActions('orders', ['trackGuestOrder']),
    ...mapMutations('orders', { unsetTrackOrderData: 'UNSET_TRACK_ORDER_DATA' })
  },
  created () {
    if (!this.trackOrderData) {
      this.trackGuestOrder({ orderNumber: this.$route.params.id, orderEmail: this.$route.query[settings.app.queryTokens.orderEmail] })
        .catch(error => {
          if (error?.response?.data?.length) {
            if (error.response.data.some(el => el.type === 'UnknownIdentifierError' || el.type === 'AccessDeniedError')) {
              this.$router.push({ path: '/', query: { [settings.app.queryTokens.openTrackOrderOnInitToken]: true } })
            }
          }
        })
    }
  },
  beforeDestroy () {
    this.unsetTrackOrderData()
  }
}
</script>
